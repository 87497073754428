<template>
  <div class="pa-6">
    <div class="font-weight-medium mb-2">
      Would you like to share your details (including your contact information,
      date of birth and particulars of your gift) with the charities you have
      nominated in your Will?
    </div>
    <v-checkbox
      label="Yes, share my details."
      color="success"
      v-model="local.shareWithCharities"
    />
    <div class="d-flex align-center">
      <v-btn
        text
        :disabled="processing"
        depressed
        @click="close"
        >{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'UserMenuPreferences',
  props: {
    closeLabel: {
      default: 'close',
    },
  },
  data: () => ({
    local: {
      shareWithCharities: false,
    },
    processing: false,
    showErrors: {
      shareWithCharities: false,
    },
  }),
  mounted() {
    this.local.shareWithCharities = this.user_meta_data.shareWithCharities
  },
  computed: {
    ...mapGetters('account', ['user_meta_data']),
    errorMessages() {
      const msgs = {
        shareWithCharities: null,
      }

      if (typeof this.localFormatted.shareWithCharities !== 'boolean') {
        msgs.shareWithCharities = 'You cannot change this option'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(
        this.localFormatted.shareWithCharities,
        this.shareWithCharities
      )
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    localFormatted() {
      return {
        shareWithCharities: this.local.shareWithCharities || false,
      }
    },
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.close()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch('account/save_user_data_deep', {
        meta: this.localFormatted,
      })
    },

    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
